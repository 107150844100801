// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.code-snippet {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.8rem;
  margin-top: 3.2rem;
  overflow: hidden;
}

.code-snippet__title {
  height: 4.8rem;
  width: 100%;

  background-color: var(--aluminium);
  color: var(--black);

  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  font-weight: 600;

  /*  responsive */

  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1.2rem 1.6rem;
}

.code-snippet__container {
  min-height: 32.4rem;
  background-color: var(--dark-aluminium);
  overflow-x: auto;
}

.code-snippet__wrapper {
  display: inline-block;
  padding: 32px;
}

.code-snippet__body {
  margin: 0;

  color: var(--white);
  font-size: 16px;
  line-height: 32px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

@media only screen and (max-width: 480px) {
  .code-snippet__title {
    /*  responsive */

    height: 4.4rem;

    font-size: 1.4rem;
    line-height: 2.2rem;
    padding: 1.2rem 1.6rem;
  }

  .code-snippet__wrapper {
    padding: 1.6rem;
  }

  .code-snippet__body {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/code-snippet.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,WAAW;;EAEX,kCAAkC;EAClC,mBAAmB;;EAEnB;4BAC0B;EAC1B,gBAAgB;;EAEhB,gBAAgB;;EAEhB,iBAAiB;EACjB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,uCAAuC;EACvC,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,SAAS;;EAET,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;EACE;IACE,gBAAgB;;IAEhB,cAAc;;IAEd,iBAAiB;IACjB,mBAAmB;IACnB,sBAAsB;EACxB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;AACF","sourcesContent":[".code-snippet {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  border-radius: 0.8rem;\n  margin-top: 3.2rem;\n  overflow: hidden;\n}\n\n.code-snippet__title {\n  height: 4.8rem;\n  width: 100%;\n\n  background-color: var(--aluminium);\n  color: var(--black);\n\n  font-family: \"Fira Code\", source-code-pro, Menlo, Monaco, Consolas,\n    \"Courier New\", monospace;\n  font-weight: 600;\n\n  /*  responsive */\n\n  font-size: 1.6rem;\n  line-height: 2.4rem;\n  padding: 1.2rem 1.6rem;\n}\n\n.code-snippet__container {\n  min-height: 32.4rem;\n  background-color: var(--dark-aluminium);\n  overflow-x: auto;\n}\n\n.code-snippet__wrapper {\n  display: inline-block;\n  padding: 32px;\n}\n\n.code-snippet__body {\n  margin: 0;\n\n  color: var(--white);\n  font-size: 16px;\n  line-height: 32px;\n  word-wrap: break-word;\n  white-space: pre-wrap;\n}\n\n@media only screen and (max-width: 480px) {\n  .code-snippet__title {\n    /*  responsive */\n\n    height: 4.4rem;\n\n    font-size: 1.4rem;\n    line-height: 2.2rem;\n    padding: 1.2rem 1.6rem;\n  }\n\n  .code-snippet__wrapper {\n    padding: 1.6rem;\n  }\n\n  .code-snippet__body {\n    font-size: 1.4rem;\n    line-height: 2.4rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
