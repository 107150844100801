// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doc-table {
    width: 100%; /* Set the width to 100% to make the table expand to fill the available width */
    border-collapse: collapse; /* Collapse table borders */
  }
  
  .doc-table th,
  .doc-table td {
    border: 1px solid #ddd; /* Add borders to table cells */
    padding: 8px; /* Add padding to table cells */
    text-align: left; /* Align text to the left within cells */
  }
  
  .doc-table th {
    background-color: #f2f2f2; /* Add background color to table header cells */
  }`, "",{"version":3,"sources":["webpack://./src/styles/components/tables.css"],"names":[],"mappings":"AAAA;IACI,WAAW,EAAE,+EAA+E;IAC5F,yBAAyB,EAAE,2BAA2B;EACxD;;EAEA;;IAEE,sBAAsB,EAAE,+BAA+B;IACvD,YAAY,EAAE,+BAA+B;IAC7C,gBAAgB,EAAE,wCAAwC;EAC5D;;EAEA;IACE,yBAAyB,EAAE,+CAA+C;EAC5E","sourcesContent":[".doc-table {\n    width: 100%; /* Set the width to 100% to make the table expand to fill the available width */\n    border-collapse: collapse; /* Collapse table borders */\n  }\n  \n  .doc-table th,\n  .doc-table td {\n    border: 1px solid #ddd; /* Add borders to table cells */\n    padding: 8px; /* Add padding to table cells */\n    text-align: left; /* Align text to the left within cells */\n  }\n  \n  .doc-table th {\n    background-color: #f2f2f2; /* Add background color to table header cells */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
