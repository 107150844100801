// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-title {
  margin: 0;
  font-weight: 400;
  color: var(--black);
}

.modal-body {
  margin-block: 2.4rem;
  color: var(--black);
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.ReactModal__Overlay {
  /* override library default */
  background-color: rgba(39, 47, 52, 0.55) !important;
}

.ReactModal__Content {
  min-width: 612px !important;
  max-width: 1200px !important;
  padding: 24px !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/layouts/modal-layout.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,QAAQ;AACV;;AAEA;EACE,6BAA6B;EAC7B,mDAAmD;AACrD;;AAEA;EACE,2BAA2B;EAC3B,4BAA4B;EAC5B,wBAAwB;AAC1B","sourcesContent":[".modal-title {\n  margin: 0;\n  font-weight: 400;\n  color: var(--black);\n}\n\n.modal-body {\n  margin-block: 2.4rem;\n  color: var(--black);\n}\n\n.modal-footer {\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n}\n\n.ReactModal__Overlay {\n  /* override library default */\n  background-color: rgba(39, 47, 52, 0.55) !important;\n}\n\n.ReactModal__Content {\n  min-width: 612px !important;\n  max-width: 1200px !important;\n  padding: 24px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
