// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.keys-description {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.api-key-container {
  display: flex;
  max-width: 1100px !important;
}

.api-key-presentation {
  max-width: 1100px !important;
  background-color: var(--white);
  border: 1px var(--sorrek-light-blue) solid;
  display: flex;
  gap: 4px;
  align-items: center;
  border-radius: 4px 0 0 4px;
  padding: 8px;
  height: 40px;
  flex-grow: 1;
}

.api-key {
  color: var(--sorrek-light-blue);
  max-width: 1100px !important;
  background-color: transparent; /* Remove background color */
  font-family: Menlo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.07px;
  padding: 0px;
  margin: 0px; /* Remove margin */
}

.copy-button {
  background-color: white;
  color: var(--sorrek-light-blue);
  border: 1px var(--sorrek-light-blue) solid;
  border-left: none;
  border-radius: 0 4px 4px 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/keys.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;EAC5B,8BAA8B;EAC9B,0CAA0C;EAC1C,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,0BAA0B;EAC1B,YAAY;EACZ,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,+BAA+B;EAC/B,4BAA4B;EAC5B,6BAA6B,EAAE,4BAA4B;EAC3D,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,WAAW,EAAE,kBAAkB;AACjC;;AAEA;EACE,uBAAuB;EACvB,+BAA+B;EAC/B,0CAA0C;EAC1C,iBAAiB;EACjB,0BAA0B;AAC5B","sourcesContent":[".keys-description {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.api-key-container {\n  display: flex;\n  max-width: 1100px !important;\n}\n\n.api-key-presentation {\n  max-width: 1100px !important;\n  background-color: var(--white);\n  border: 1px var(--sorrek-light-blue) solid;\n  display: flex;\n  gap: 4px;\n  align-items: center;\n  border-radius: 4px 0 0 4px;\n  padding: 8px;\n  height: 40px;\n  flex-grow: 1;\n}\n\n.api-key {\n  color: var(--sorrek-light-blue);\n  max-width: 1100px !important;\n  background-color: transparent; /* Remove background color */\n  font-family: Menlo;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  letter-spacing: 0.07px;\n  padding: 0px;\n  margin: 0px; /* Remove margin */\n}\n\n.copy-button {\n  background-color: white;\n  color: var(--sorrek-light-blue);\n  border: 1px var(--sorrek-light-blue) solid;\n  border-left: none;\n  border-radius: 0 4px 4px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
