// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-nav-bar {
  width: 100%;
}

app-nav-bar-tabs {
  flex: 1 1;
}

app-nav-bar-tab {
  margin-right: 24px;
}

app-nav-bar-tab:last-child {
  margin-right: 0;
}

app-login-button,
app-signup-button,
app-logout-button {
  margin-right: 1.6rem;
}

app-login-button:last-child,
app-signup-button:last-child,
app-logout-button:last-child {
  margin-right: 0;
}

app-page-loader {
  display: flex;
  height: 100%;
  width: 100%;
}

app-mobile-nav-bar {
  width: 100%;
}

app-mobile-nav-bar-tab {
  width: 100%;
}

app-mobile-nav-bar-brand {
  flex: 1 1;
}

`, "",{"version":3,"sources":["webpack://./src/styles/components/angular.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;;;EAGE,oBAAoB;AACtB;;AAEA;;;EAGE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,SAAO;AACT","sourcesContent":["app-nav-bar {\n  width: 100%;\n}\n\napp-nav-bar-tabs {\n  flex: 1;\n}\n\napp-nav-bar-tab {\n  margin-right: 24px;\n}\n\napp-nav-bar-tab:last-child {\n  margin-right: 0;\n}\n\napp-login-button,\napp-signup-button,\napp-logout-button {\n  margin-right: 1.6rem;\n}\n\napp-login-button:last-child,\napp-signup-button:last-child,\napp-logout-button:last-child {\n  margin-right: 0;\n}\n\napp-page-loader {\n  display: flex;\n  height: 100%;\n  width: 100%;\n}\n\napp-mobile-nav-bar {\n  width: 100%;\n}\n\napp-mobile-nav-bar-tab {\n  width: 100%;\n}\n\napp-mobile-nav-bar-brand {\n  flex: 1;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
