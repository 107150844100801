// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;
}

.page-layout > * {
  padding-inline: 24px;
}

.page-layout__content {
  flex: 1 1;
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  white-space: pre-wrap;
}

.page-layout__content > * {
  width: 100%;
  max-width: 1200px;
}

.page-layout__focus {
  background: white;
  padding: 3rem;
  width: 100%;
}

.page-layout__focus.dashboards {
  max-width: 1800px;
}

@media only screen and (max-width: 640px) {
  .page-layout__content {
    margin-top: 6.4rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/layouts/page-layout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;;EAEnB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,SAAO;EACP,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".page-layout {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  height: 100%;\n  width: 100%;\n}\n\n.page-layout > * {\n  padding-inline: 24px;\n}\n\n.page-layout__content {\n  flex: 1;\n  flex-basis: auto;\n  flex-shrink: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  white-space: pre-wrap;\n}\n\n.page-layout__content > * {\n  width: 100%;\n  max-width: 1200px;\n}\n\n.page-layout__focus {\n  background: white;\n  padding: 3rem;\n  width: 100%;\n}\n\n.page-layout__focus.dashboards {\n  max-width: 1800px;\n}\n\n@media only screen and (max-width: 640px) {\n  .page-layout__content {\n    margin-top: 6.4rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
