import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { PageLayout } from "../../page-layout";
import { PageLoader } from "../../page-loader";

// eslint-disable-next-line import/no-webpack-loader-syntax
import apiDocSource from "!html-loader!../../../docs/api.html";
import { SignupButton } from "../../buttons/signup-button";
import { LoginButton } from "../../buttons/login-button";

function Auth0Login() {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <PageLoader />;
  }

  const apiDocHTML = { __html: apiDocSource };

  return (
    <PageLayout>
      <div className="login-page">
        <h1>Welcome to the Sorrek Domain Enrichment API!</h1>
        {!isAuthenticated && (
          <h4>
            <SignupButton isLink /> or <LoginButton isLink /> to get started.
          </h4>
        )}

        <div className="page-layout__focus">
          <div dangerouslySetInnerHTML={apiDocHTML}></div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Auth0Login;
