// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-layout {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  width: 100%;
  min-height: 640px;

  /* responsive */

  padding: 48px;
}

.content__title {
  margin-top: 0;

  color: var(--white);
}

.content__body {
  /* responsive */

  font-size: 1.6rem;
  line-height: 2.4rem;
}

#page-description {
  display: flex;
  flex-direction: column;
}

#page-description span {
  margin-bottom: 1.6rem;
}

#page-description span:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 480px) {
  .content-layout {
    padding: 1.6rem;
  }

  .content__title {
    font-size: 2.4rem;
  }
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/layouts/content-layout.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,cAAc;;EAEd,WAAW;EACX,iBAAiB;;EAEjB,eAAe;;EAEf,aAAa;AACf;;AAEA;EACE,aAAa;;EAEb,mBAAmB;AACrB;;AAEA;EACE,eAAe;;EAEf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".content-layout {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 0;\n\n  width: 100%;\n  min-height: 640px;\n\n  /* responsive */\n\n  padding: 48px;\n}\n\n.content__title {\n  margin-top: 0;\n\n  color: var(--white);\n}\n\n.content__body {\n  /* responsive */\n\n  font-size: 1.6rem;\n  line-height: 2.4rem;\n}\n\n#page-description {\n  display: flex;\n  flex-direction: column;\n}\n\n#page-description span {\n  margin-bottom: 1.6rem;\n}\n\n#page-description span:last-child {\n  margin-bottom: 0;\n}\n\n@media only screen and (max-width: 480px) {\n  .content-layout {\n    padding: 1.6rem;\n  }\n\n  .content__title {\n    font-size: 2.4rem;\n  }\n}\n\n.user-profile {\n  display: flex;\n  align-items: center;\n  gap: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
