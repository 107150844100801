// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container */
.signup-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--black);
    border-radius: 5px;
  }
  
  /* Form Title */
  .signup-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Form Input Fields */
  .signup-form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 0.1rem solid var(--sorrek-light-blue);
    border-radius: 4px;
  }
  
  /* Form Button */
  .signup-form-button {
    width: 100%;
    padding: 10px;
    background-color: var(--sorrek-light-blue);
    color: var(--white);
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Form Button Hover */
  .signup-form-button:hover {
    background-color: var(--sorrek-light-blue);
  }
  
  /* Error Message */
  .signup-error-message {
    color: red;
    margin-top: 10px;
  }
  
  /* Success Message */
  .signup-success-message {
    color: var(--sorrek-light-blue);
    margin-top: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/components/signup.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;EACpB;;EAEA,eAAe;EACf;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA,sBAAsB;EACtB;IACE,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,6CAA6C;IAC7C,kBAAkB;EACpB;;EAEA,gBAAgB;EAChB;IACE,WAAW;IACX,aAAa;IACb,0CAA0C;IAC1C,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA,sBAAsB;EACtB;IACE,0CAA0C;EAC5C;;EAEA,kBAAkB;EAClB;IACE,UAAU;IACV,gBAAgB;EAClB;;EAEA,oBAAoB;EACpB;IACE,+BAA+B;IAC/B,gBAAgB;EAClB","sourcesContent":["/* Container */\n.signup-container {\n    max-width: 400px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: var(--black);\n    border-radius: 5px;\n  }\n  \n  /* Form Title */\n  .signup-title {\n    font-size: 24px;\n    font-weight: bold;\n    margin-bottom: 20px;\n    text-align: center;\n  }\n  \n  /* Form Input Fields */\n  .signup-form-input {\n    width: 100%;\n    padding: 10px;\n    margin-bottom: 15px;\n    border: 0.1rem solid var(--sorrek-light-blue);\n    border-radius: 4px;\n  }\n  \n  /* Form Button */\n  .signup-form-button {\n    width: 100%;\n    padding: 10px;\n    background-color: var(--sorrek-light-blue);\n    color: var(--white);\n    font-weight: bold;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  /* Form Button Hover */\n  .signup-form-button:hover {\n    background-color: var(--sorrek-light-blue);\n  }\n  \n  /* Error Message */\n  .signup-error-message {\n    color: red;\n    margin-top: 10px;\n  }\n  \n  /* Success Message */\n  .signup-success-message {\n    color: var(--sorrek-light-blue);\n    margin-top: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
