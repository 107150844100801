import React from 'react';
import { PageLoader } from './page-loader';
import { withAuthenticationRequired } from "@auth0/auth0-react";

export const AuthenticationGuard = ({ children, component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    ),
  });

  return <Component />;
};
