import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const LoginButtonWithAuth0 = ({isLink}) => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
      authorizationParams: {
        prompt: "login",
      },
      scope: 'openid profile email offline_access',
    });
  };
  const className = isLink ? " button__link lowercase" : "button__login";

  return (
    <button className={className} onClick={handleLogin}>
      Log In
    </button>
  );
};

export const LoginButton = ({isLink}) => {
  return <LoginButtonWithAuth0 isLink={isLink} />;
};



