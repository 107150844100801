import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../../page-layout";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PageLoader } from "../../page-loader";

const Auth0Dashboard = (props) => {
  const { user: auth0User, isLoading: auth0IsLoading } = useAuth0();
  const { fetchEmbedInfo } = props;
  const [isLoading, setIsLoading] = useState(true); // Separate state for loading indicator

  let user = auth0User;

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const checkout_session_id = searchParams.get("checkout-session");
  const [error, setError] = useState();
  const [iFrameSrcLiveEvent, setIFrameSrcLiveEvent] = useState();
  const [iFrameSrcTimeSeries, setIFrameSrcTimeSeries] = useState();

  useEffect(() => {
    if (auth0IsLoading) {
      // Show loading indicator if Auth0 is still loading
      setIsLoading(true);
      return;
    }

    setIsLoading(false); // Auth0 finished loading, set loading to false

    if(checkout_session_id) {
      fetch(`${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/register/stripe/${checkout_session_id}`, {
        method: 'POST',
        headers: {
          // 'Authorization': should be the auth0 access token.
        }
      })
      .then(res => res.json())
      .then(
        (result) => {
          fetchEmbedInfo(result.customer, setIFrameSrcLiveEvent, setIFrameSrcTimeSeries, setError)
        }
      ).catch((err) => {
        setError(err);
      });
    }
    else {
      fetch(`${process.env.REACT_APP_DEV_PORTAL_API_SERVER}/stripe/customer?email=` + encodeURIComponent(user.email), {
        headers: {
          // 'Authorization': should be the auth0 access token when ready.
        }
      }).then(res => res.json())
      .then(
        (customer) => {
          fetchEmbedInfo(customer.id, setIFrameSrcLiveEvent, setIFrameSrcTimeSeries, setError);
        }
      ).catch((err) => {
        setError(err);
      });
    }
  }, [auth0IsLoading, navigate, checkout_session_id, user, fetchEmbedInfo]);

  // Render loading indicator if isLoading is true
  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <PageLayout>
      {/* No Moesif integration content */}
    </PageLayout>
  );
}

export default Auth0Dashboard;
