// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-grid {
  display: flex;
  flex-direction: column;

  /* responsive */

  margin-top: 48px;
}

.profile__header {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 16px;
}

.profile__avatar {
  border: solid 2px var(--aqua);
  border-radius: 50%;

  /* responsive */

  height: 80px;
  width: 80px;
}

.profile__headline {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.profile__title {
  margin-top: 0;
  margin-bottom: 0;

  color: var(--white);
}

.profile__details {
  /* responsive */

  margin-top: 32px;
}

@media only screen and (max-width: 480px) {
  .profile-grid {
    margin-top: 2.4rem;
  }

  .profile__avatar {
    height: 5.6rem;
    width: 5.6rem;

    border-radius: 50%;

    border: solid 0.13rem var(--aqua);
  }

  .profile__title {
    font-size: 1.6rem;
  }

  .profile__description {
    font-size: 1.3rem;
  }

  .profile__details {
    margin-top: 1.6rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/grids/profile-grid.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;;EAEtB,eAAe;;EAEf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,+BAA+B;EAC/B,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,kBAAkB;;EAElB,eAAe;;EAEf,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,gBAAgB;;EAEhB,mBAAmB;AACrB;;AAEA;EACE,eAAe;;EAEf,gBAAgB;AAClB;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,aAAa;;IAEb,kBAAkB;;IAElB,iCAAiC;EACnC;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".profile-grid {\n  display: flex;\n  flex-direction: column;\n\n  /* responsive */\n\n  margin-top: 48px;\n}\n\n.profile__header {\n  display: grid;\n  grid-template-columns: auto 1fr;\n  column-gap: 16px;\n}\n\n.profile__avatar {\n  border: solid 2px var(--aqua);\n  border-radius: 50%;\n\n  /* responsive */\n\n  height: 80px;\n  width: 80px;\n}\n\n.profile__headline {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n}\n\n.profile__title {\n  margin-top: 0;\n  margin-bottom: 0;\n\n  color: var(--white);\n}\n\n.profile__details {\n  /* responsive */\n\n  margin-top: 32px;\n}\n\n@media only screen and (max-width: 480px) {\n  .profile-grid {\n    margin-top: 2.4rem;\n  }\n\n  .profile__avatar {\n    height: 5.6rem;\n    width: 5.6rem;\n\n    border-radius: 50%;\n\n    border: solid 0.13rem var(--aqua);\n  }\n\n  .profile__title {\n    font-size: 1.6rem;\n  }\n\n  .profile__description {\n    font-size: 1.3rem;\n  }\n\n  .profile__details {\n    margin-top: 1.6rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
