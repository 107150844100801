// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stripe-pricing-table-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto; /* Adds horizontal scrolling if needed */
    gap: 16px; /* Adds some space between the tiles */
  }
  
.stripe-pricing-table-container stripe-pricing-table {
    flex: 1 1 auto; /* Makes each tile take equal space */
    min-width: 200px; /* Ensures a minimum width for each tile */
  }`, "",{"version":3,"sources":["webpack://./src/styles/components/pricing-table.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,gBAAgB,EAAE,wCAAwC;IAC1D,SAAS,EAAE,sCAAsC;EACnD;;AAEF;IACI,cAAc,EAAE,qCAAqC;IACrD,gBAAgB,EAAE,0CAA0C;EAC9D","sourcesContent":[".stripe-pricing-table-container {\n    display: flex;\n    flex-wrap: nowrap;\n    overflow-x: auto; /* Adds horizontal scrolling if needed */\n    gap: 16px; /* Adds some space between the tiles */\n  }\n  \n.stripe-pricing-table-container stripe-pricing-table {\n    flex: 1 1 auto; /* Makes each tile take equal space */\n    min-width: 200px; /* Ensures a minimum width for each tile */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
